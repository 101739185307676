.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  min-height: 300px;
  border-radius: 8px;
  padding: 32px;
  background: rgb(36,36,36);
  background: linear-gradient(180deg, rgba(36,36,36,1) 0%, rgba(0,110,255,1) 100%);
}
.login input[type=password] {
  font-size: 32px;
  font-family: Helvetica, Arial, sans-serif;
  width: 100%;
  text-align: center;
  margin: 12px 0;
}
.btn-login {
  cursor: pointer;
  padding: 12px;
  font-weight: 800;
  background: linear-gradient(180deg, rgb(57, 228, 0) 0%, rgb(0, 139, 16) 100%);
  border: 3px solid rgb(195, 255, 83);
  color: white;
  margin-right: 8px;
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 16px;
}