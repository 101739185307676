.tableProduct {
  padding: 32px 0;
  border-bottom: 1px solid red;
}
.tableProduct td {
  vertical-align: top;
}
.tableProduct input {
  width: 100%;
}
.tableProduct .sizes {
  display: flex;
}
.tableProduct .actions {
  padding-top: 28px;
  /* display: flex;
  flex-direction: column; */
}
.tableProduct .actions .btn {
  width: 100%;
  height: 32px;
}